<template>
  <main>
    <div class="left ml-8 mt-8">
      <v-card>
        <img
          src="https://cdn.vuetifyjs.com/images/carousel/planet.jpg"
          height="600"
          width="350"
        />
      </v-card>
    </div>
    <div class="right ml-8 mt-8">
      <div class="cnt1">
        <div class="head d-flex">
          <div class="head1">
            <v-icon left class="mb-8 red--text" size="35">mdi-basket</v-icon
            ><!-- <h2>Order details</h2> -->
            <h2>Order details</h2>
          </div>
          <div class="head2">
            <v-btn outlined color="red"> Order Again </v-btn>
          </div>
        </div>
        <v-card>
          <v-card-text>
            <v-stepper class="elevation-0">
              <v-stepper-header>
                <v-stepper-step editable step="1">
                  <v-badge bordered color="red" icon="mdi-check" overlap>
                    <v-btn class="white--text" color="red" rounded>
                      <v-icon>mdi-package-variant-closed </v-icon>
                    </v-btn>
                  </v-badge>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step editable step="2">
                  <v-badge bordered color="error" icon="mdi-check" overlap>
                    <v-btn class="white--text" color="red" rounded>
                      <v-icon>mdi-truck-fast</v-icon>
                    </v-btn>
                  </v-badge>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" editable>
                  <v-badge bordered color="red" overlap>
                    <v-btn class="white--text" color="red" rounded>
                      <v-icon>mdi-package-variant</v-icon>
                    </v-btn>
                  </v-badge>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
            <div class="chip d-flex justify-end">
              <v-chip color="red" class="white--text ma-2"
                >Estimated Delivary date 20th AUGEST</v-chip
              >
            </div>
          </v-card-text>
          <!-- <v-btn rounded color="red" class="ml-12" dark
            >Estimated Delivary date 20th AUGEST</v-btn> -->
        </v-card>
      </div>

      <v-card class="heading mt-6 pt-2 blue-grey lighten-5">
        <p class="subtitle mt-1">Order Id : 1234567890</p>
        <p class="subtitle mt-1">Placed On : 20 Aug 2021</p>
        <p class="subtitle mt-1">Delivered On : 31 Aug 2021</p>
      </v-card>

      <div class="table">
        <v-card class="mt-5">
          <v-simple-table class="table1 rounded-pill">
            <template v-slot:default>
              <thead class="blue-grey lighten-5">
                <tr>
                  <th class="text-h6">Product Name</th>
                  <th class="text-h6">Quantity</th>
                  <th class="text-h6">Price</th>
                  <th class="text-h6">View Product</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in order" :key="item.name">
                  <td class="mt-5 subtitle-1">
                    {{ item.productname }} <br />
                    <!-- <p class="ml-8">${{ item.price }}.4</p> -->
                  </td>
                  <td class="ml-2">{{ item.quantity }}</td>
                  <td>{{ item.price }}.48</td>
                  <!-- <td>{{ item.deliveredon }}</td> -->
                  <td>
                    <v-btn small text color="error">View Product</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>

      <div class="bottom1 mt-6">
        <div class="bottom1left">
          <v-card height="100" width="420" class="mr-6 rounded-lg">
            <h2 class="ml-6 pt-5">Shipping Address</h2>
            <p class="text--secondary font-weight-medium ml-6">
              Kelly Williams 77 Avenue MA 3562
            </p>
          </v-card>
        </div>
        <div class="bottom1right">
          <v-card height="270" width="400" class="rounded-lg">
            <h2 class="ml-6 pt-5">Total Summary</h2>
            <div class="detail">
              <div class="dl ml-6">
                <p class="text--secondary">Subtotal:</p>
                <p class="text--secondary">Shipping fee :</p>
                <p class="text--secondary">Discount :</p>
              </div>
              <div class="dr mr-6">
                <p class="font-weight-black">$555</p>
                <p class="font-weight-black">$55</p>
                <p class="font-weight-black">$5</p>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="totalc mt-2">
              <p class="ml-6 font-weight-black text-h6">Total :</p>
              <p class="font-weight-black mr-6">$650</p>
            </div>
            <p class="ml-6 pt-0">Paid by credit card / debit card</p>
          </v-card>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "myOrder",
  data() {
    return {
      order: [
        {
          productname: "Frozen Yogurt",
          quantity: 159,
          price: "55",
          deliveredon: "AUG",
        },
        {
          productname: "Ice cream sandwich",
          quantity: 237,
          price: "78",
          deliveredon: "AUG",
        },
        {
          productname: "Eclair",
          quantity: 262,
          price: "90",
          deliveredon: "AUG",
        },
        {
          productname: "Cupcake",
          quantity: 305,
          price: "33",
          deliveredon: "AUG",
        },
        {
          productname: "Gingerbread",
          quantity: 356,
          price: "74",
          deliveredon: "AUG",
        },
        {
          productname: "Wheatbread",
          quantity: 16,
          price: "740",
          deliveredon: "AUG",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  display: flex;
}
.heading {
  display: flex;
  justify-content: space-evenly;
}
.head {
  display: flex;
  justify-content: space-between;
}
.head1 {
  display: flex;
  justify-content: start;
}
.bottom1 {
  display: flex;
  justify-content: space-between;
}
.detail,
.totalc {
  display: flex;
  justify-content: space-between;
}
.table {
  border-radius: 5px red;
}
.left {
  img {
    object-fit: cover;
  }
}
@media (max-width: 780px) {
  main {
    display: flex;
    flex-direction: column;
  }
  .left {
    min-height: 150px;
  }
  .bottom1 {
    display: flex;
    flex-direction: column;

    .bottom1right {
      margin-top: 15px;
      margin-right: 10px;
    }
  }
  .heading {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .left {
    height: 150px;
    width: 150px;
    .v-card {
      height: 150px;
      width: 150px;
      img {
        height: 150px;
        width: 300px;
      }
    }
  }
}
</style>